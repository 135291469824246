import MicroModal from 'micromodal'
import isEmailValidator from 'validator/lib/isEmail';
import isNumericValidator from 'validator/lib/isNumeric';
import Splide from '@splidejs/splide';

import {send} from 'emailjs-com';

document.addEventListener('DOMContentLoaded', () => {
  const listeners = document.getElementsByClassName('onClick');
  const slideElement = document.getElementsByClassName('splide');
  console.log(slideElement, 'slideElement')
  new Splide('.splide', {
    type: 'loop',
    autoWidth: true,
    autoHeight: true,
    arrows: false,
    autoplay: true,
    interval: 3000,
  }).mount();

  if (listeners) {
    for (const target of listeners) {
      target.addEventListener('click', (e) => {
        e.preventDefault();

        const href = target.getAttribute('href')

        if (href) {
          const element = document.getElementById(href.slice(1, href.length + 1))

          window.scrollTo({
            top: element.offsetTop,
            behavior: "smooth"
          })
        }
      })
    }
  }

  /* Modal */
  MicroModal.init();

  /* Mail service */
  const btn = document.getElementById('modal__send-btn');

  btn.addEventListener('click', () => {
    btn.disabled = true;

    const emailEl = document.getElementById('input__email')
    const phoneEl = document.getElementById('input__phone')
    const msgEl = document.getElementById('input__message')

    const isEmail = isEmailValidator(emailEl.value)
    const isNumeric = isNumericValidator(phoneEl.value)

    const isValid = isEmail && isNumeric;

    if (isValid) {
      send("service_5jvx83l", "svaitver_32xgeb8", {
        phone: phoneEl.value,
        email: emailEl.value,
        msg: msgEl.value || 'Без сообщения',
      }, 'user_8vzvAAkfdvB2ios96eBG2').then(res => {
        const {status, text} = res;

        if (status === 200) {
          emailEl.value = '';
          phoneEl.value = '';
          msgEl.value = '';

          btn.disabled = false;

          MicroModal.close('modal-1');

          MicroModal.show('modal-2');

          setTimeout(() => {
            MicroModal.close('modal-2');
          }, 5000)
        }

        if (status !== 200) {
          alert('Нет возможности отправить сообщение, позвоните нам по указанным номерам.')
        }
      })
    } else {
      alert("Проверьте введенные данные. Поля отмеченные * обязательны для заполнения")
      btn.disabled = false;
    }
  })
})
